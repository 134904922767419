/*global pageflowAnalyticsSetup*/

import 'pageflow-scrolled/frontend/index.css';
import 'pageflow-scrolled/contentElements-frontend.css';

import {registerTemplateWidgetType} from 'pageflow-scrolled/frontend';
import 'pageflow-scrolled/contentElements-frontend';

import {consent, cookies, events} from 'pageflow/frontend';

registerTemplateWidgetType('pageflow-analytics', element => {
  pageflowAnalyticsSetup(element, events, cookies, consent);
});

require.context('../pageflow-scrolled/themes', true);
